import React from "react";
import styled from "styled-components";

const ListItem = styled.div`
  padding: 10px;
  text-align: left;
  cursor: pointer;
  background-color: #ddd;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ContactName = styled.div`
  font-size: 18px;
  font-weight: bold;
  word-wrap: break-word;
  overflow-wrap: anywhere;
`;

// const MessageButton = styled.button`
//   padding: 5px;
//   border-radius: 5px;
//   cursor: pointer;
// `;

// const MessageContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
// `;

// const MessageInput = styled.textarea`
//   padding: 5px;
//   border-radius: 5px;
//   border: 1px solid #ccc;
//   font-family: "Open Sans", sans-serif;
// `;

// const ButtonGroup = styled.div`
//   display: flex;
//   gap: 10px;
//   align-items: center;
// `;

// const SendButton = styled.button`
//   padding: 5px;
//   border-radius: 5px;
//   cursor: pointer;
// `;

// const CancelButton = styled.button`
//   padding: 5px;
//   border-radius: 5px;
//   cursor: pointer;
// `;

const ContactListItem = ({ contact }) => {
  //   const [showMessageInput, setShowMessageInput] = useState(false);
  //   const [message, setMessage] = useState("");

  //   const handleSendMessage = async (data) => {
  //     // send message
  //     const messageData = {
  //       receiver_id: listing.created_by_user_id,
  //       listing_id: listing.id,
  //       message: data.message,
  //     };
  //     console.log("Message form submitted", messageData);

  //     try {
  //       await sendMessage(messageData);
  //       setSuccess(true);
  //     } catch (error) {
  //       setError(true);
  //       console.log(error);
  //     }
  //   };

  return (
    <ListItem>
      <div>
        <img src={contact.avatar} alt="avatar" width={35} />
      </div>
      <div>
        <ContactName>{contact.name}</ContactName>
        {/* {!showMessageInput && (
          <MessageButton onClick={() => setShowMessageInput(true)}>
            Send Message
          </MessageButton>
        )}
        {showMessageInput && (
          <MessageContainer>
            <MessageInput
              type="text"
              placeholder="Message"
              onChange={(e) => setMessage(e.target.value)}
            />
            <ButtonGroup>
              <SendButton onClick={handleSendMessage}>Send Message</SendButton>
              <CancelButton onClick={() => setShowMessageInput(false)}>
                Cancel
              </CancelButton>
            </ButtonGroup>
          </MessageContainer>
        )} */}
      </div>
    </ListItem>
  );
};

export default ContactListItem;
