import React, { useState } from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  padding: 10px;
  text-align: left;
  cursor: pointer;
  width: 200px;
`;

const CardImage = styled.img`
  border-radius: 5px;
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  height: 175px;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  height: 175px;
  background-color: ${(props) =>
    props.hovered ? "rgba(0, 0, 0, 0.3)" : "transparent"};
`;

const Title = styled.div`
  font-size: 18px;
  text-transform: capitalize;
  font-weight: bold;
  text-decoration: ${(props) => (props.isHovered ? "underline" : "none")};
`;

const Price = styled.div`
  font-size: 16px;
  font-weight: ${(props) => (props.isHovered ? "bold" : "normal")};
`;

const ListingPreview = ({ listing }) => {
  const [isHovered, setIsHovered] = useState(false);
  const {
    listing_assets,
    listing_name,
    listing_unit_price,
    listing_unit_time,
  } = listing;

  return (
    <Container
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ImageContainer>
        <ImageOverlay hovered={isHovered} />
        <CardImage src={listing_assets[0].asset_uri} />
      </ImageContainer>
      <Title isHovered={isHovered}>{listing_name}</Title>
      <Price isHovered={isHovered}>
        ${listing_unit_price} / {listing_unit_time}
      </Price>
    </Container>
  );
};

export default ListingPreview;
