import React from "react";
import styled from "@emotion/styled";
import { colors } from "theme";
import useMessagesApi from "../../../api/messages";

const ChatsContainer = styled.div``;

const ChatsTitle = styled.div`
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const ChatLine = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: ${colors.offWhite};
  box-shadow: 2px 2px 2px 1px #d9d9d9;
  cursor: pointer;
  background: ${(props) => (props.selected ? colors.blue : "")};
  color: ${(props) => (props.selected ? colors.offWhite : "")};
  &:hover {
    background: ${colors.blue};
    color: ${colors.offWhite};
  }
`;

const TitleSpan = styled.span`
  font-size: 14px;
  float: left;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ListingName = styled.span`
  font-weight: bold;
`;

const UnreadCount = styled.span`
  font-size: 12px;
  background: ${colors.pink};
  color: ${colors.offWhite};
  padding: 2px;
  border-radius: 5%;
  margin-left: 3px;
`;

const Chats = ({
  messages,
  selectedChat,
  setSelectedChat,
  selectedUser,
  setSelectedUser,
  refresh,
}) => {
  const { markMessagesAsRead } = useMessagesApi();
  const goBack = () => {
    selectedChat ? setSelectedChat(null) : setSelectedUser(null);
    refresh();
  };

  console.log("selected User", selectedUser);

  const unreadCount = (messages) => {
    if (selectedUser) {
      return messages.filter(
        (message) =>
          message.message.is_read !== true &&
          message.message.sender_id === selectedUser
      ).length;
    }
  };

  const handleSelectListingChat = async (listingId) => {
    // handle the call here to mark all the messages regarding this listing that are received to this user from the sender user, as read.
    try {
      await markMessagesAsRead(listingId);
      setSelectedChat(listingId);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ChatsContainer>
      <ChatsTitle>
        {selectedUser && <TitleSpan onClick={goBack}>&larr; Back</TitleSpan>}
        {selectedUser ? messages[selectedUser].user_name : "Users"}
      </ChatsTitle>
      {!selectedUser &&
        Object.entries(messages).map(([k, v]) => {
          return (
            <ChatLine
              key={k}
              selected={false}
              onClick={() => setSelectedUser(k)}
            >
              From: {v.user_name}{" "}
              {v.unread_count !== 0 && (
                <UnreadCount>{v.unread_count} Unread</UnreadCount>
              )}
            </ChatLine>
          );
        })}

      {selectedUser &&
        Object.entries(messages[selectedUser]).map(([k, v]) => {
          return k !== "user_name" && k !== "unread_count" ? (
            <ChatLine
              key={k}
              selected={false}
              onClick={() => handleSelectListingChat(k)}
            >
              <ListingName>{v[0].listing.listing_name}</ListingName> -{" "}
              {v.length} message(s)
              {unreadCount(v) !== 0 && (
                <UnreadCount>{unreadCount(v)} Unread</UnreadCount>
              )}
            </ChatLine>
          ) : (
            <div></div>
          );
        })}
    </ChatsContainer>
  );
};

export default Chats;
