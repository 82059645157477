import React from "react";
import { Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: 50,
    borderTop: "1px solid black",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 50,
    alignItems: "center",
    backgroundColor: theme.palette.primary.mainBackgroundColor,
  },
  footerItem: {
    padding: 5,
  },

  termsAndConditions: {
    textDecoration: "underline",
    cursor: "pointer",
    "& a": {
      color: "black",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const year = new Date().getFullYear();
  return (
    <Box className={classes.container}>
      <Box className={classes.footerItem}>Rent Art Stuff &#169;{year}</Box>
      <Box className={classes.termsAndConditions}>
        <a
          href="/terms-and-conditions/terms-and-conditions.pdf"
          target="_blank"
          title="RentArtStuff.com Terms & Conditions"
        >
          Terms & Conditions
        </a>
      </Box>
      <Box>info@rentartstuff.com</Box>
    </Box>
  );
};

export default Footer;
