import PageWrapper from "components/PageWrapper";
import ListingSection from "./ListingSection";
import { colors } from "theme";
import styled from "@emotion/styled";
import { homePageImages } from "theme/images";
import { Box, useMediaQuery } from "@mui/material";
import { SearchBox } from "components/SearchBox";
import { navigate } from "@reach/router";

// TODO: Adjust styles for under 800px
const Homepage = () => {
  const isMobile = useMediaQuery("(max-width:800px)");

  return (
    <PageWrapper isNavBarShowing isFullWidth>
      <HeroImage />
      <Box
        width="100%"
        display="flex"
        padding="0 2rem"
        maxWidth="70rem"
        margin="0 auto"
        sx={{ "@media (max-width: 800px)": { flexDirection: "column" } }}
      >
        <Box flex="2" position="relative">
          <PersonImage />
        </Box>
        <Box
          flex="3"
          minHeight="12rem"
          sx={{ marginTop: `${isMobile ? "4rem" : "0"}`, marginBottom: "2rem" }}
        >
          <h1 style={{ marginBottom: "0" }}>What are you looking for?</h1>
          <SearchBox />
          <p>
            We know it's hard to find specialized creative tools. It can be
            prohibitively expensive to buy them outright, especially when your
            usage will be limited. We also know that those same tools are often
            collecting dust in garages and storage units around the city.
          </p>
          <p style={{ paddingTop: 15 }}>
            Our Site is still under development! Please report any issues or
            suggestions{" "}
            <span
              style={{ color: `${colors.blue}`, cursor: "pointer" }}
              onClick={() => navigate("/faq")}
            >
              here
            </span>
            . We appreciate your support!
          </p>
        </Box>
      </Box>
      <ListingSection />
    </PageWrapper>
  );
};

const imageObject =
  homePageImages[Math.floor(Math.random() * homePageImages.length)];

const HeroImage = styled.div`
  height: 60vh;
  margin-top: -5vh;
  width: 100%;
  background-image: url(${imageObject.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80% center;
`;

const PersonImage = styled.div`
  height: 160%;
  background-image: url(${imageObject.person});
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  @media (max-width: 800px) {
    background-image: url(${imageObject.mobilePerson});
    height: 300px;
    width: 300px;
    bottom: -100px;
    left: 0;
  }
`;

export default Homepage;
