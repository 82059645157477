import { Box, Grid, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import UpdatedCard from "components/ListingCard/UpdatedCard";
import useSWR from "swr";
import { ENDPOINTS } from "api/endpoints";
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
    backgroundColor: colors.lightYellow,
  },
  header: {
    fontSize: 25,
    marginBottom: 20,
    paddingTop: (props) => (props.mobile ? 0 : 50),
    paddingBottom: (props) => (props.mobile ? 0 : 50),
  },
}));

const ListingSection = () => {
  const mobile = useMediaQuery("(min-width:800px)");
  const { data: listings, isLoading, error } = useSWR(ENDPOINTS.LISTINGS_HOME);
  const classes = useStyles({ mobile });
  return (
    <Box className={classes.container}>
      <Box className={classes.header}>FEATURED LISTINGS</Box>
      {!listings && <Box component="h1">Loading...</Box>}
      <Grid container>
        {listings &&
          !isLoading &&
          !error &&
          listings.map((listing, index) => (
            <UpdatedCard listing={listing} key={index + "small"} />
          ))}
      </Grid>
    </Box>
  );
};
export default ListingSection;
