import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import useSWR from "swr";
import { ENDPOINTS } from "../../../api/endpoints";
import ContactList from "./ContactList";

const ContactsContainer = styled.div`
  padding: 10px;
`;

const SectionHeader = styled.div`
  font-size: 28px;
  text-align: left;
  margin: 10px;
  border-bottom: 1px solid #ddd;
`;

export const CardGroup = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isMobile ? "100%" : "25% 25% 25% 25%"};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isMobile ? "center" : "flex-start")};
`;

// const InviteContactButton = styled.button`
//   font-size: 26px;
//   padding: 5px;
//   width: 200px;
//   background: ${colors.blue};
//   border-radius: 5px;
//   color: white;
//   margin: 10px;
//   cursor: pointer;
// `;

const Contacts = ({ isMobile }) => {
  const [contacts, setContacts] = useState([]);
  const { data } = useSWR(ENDPOINTS.CONTACTS);

  useEffect(() => {
    if (data) {
      setContacts(data);
    }
  }, [data]);

  // TODO: create a way to send an invite to the app
  // const SendInvitation = () => {};

  return (
    <ContactsContainer>
      <ButtonContainer isMobile={isMobile}>
        {/* <InviteContactButton onClick={SendInvitation}>
          Invite
        </InviteContactButton> */}
      </ButtonContainer>
      <SectionHeader>Contacts</SectionHeader>
      <ContactList contacts={contacts} />
    </ContactsContainer>
  );
};

export default Contacts;
