import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { CardGroup, Tab, TabGroup } from "../styled";
import { RENTAL_TABS } from "../../_static";
import { ENDPOINTS } from "../../../../api/endpoints";
import useSWR from "swr";
import RentalCard from "./RentalCard";

const RentalsContainer = styled.div``;

const Rentals = ({ user, isMobile }) => {
  const [currentTab, setCurrentTab] = useState("Created");
  const [currentData, setCurrentData] = useState(null);
  const [sentCount, setSentCount] = useState(0);
  const [receivedCount, setReceivedCount] = useState(0);

  const { data: rentals, mutate: refreshRentals } = useSWR(ENDPOINTS.RENTALS);

  useEffect(() => {
    const created = rentals?.filter(
      (request) => request.created_by_user_id === user.internal_user_id
    );

    const received = rentals?.filter(
      (request) => request.created_by_user_id !== user.internal_user_id
    );

    setCurrentData(currentTab === "Created" ? created : received);
    setSentCount(created?.length || 0);
    setReceivedCount(received?.length || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, rentals]);

  return (
    <RentalsContainer>
      <TabGroup>
        {RENTAL_TABS.map((tab) => (
          <Tab
            key={tab.title}
            onClick={() => setCurrentTab(tab.title)}
            selected={tab.title === currentTab}
          >
            {tab.title}{" "}
            {tab.title === "Sent" ? `(${sentCount})` : `(${receivedCount})`}
          </Tab>
        ))}
      </TabGroup>
      <CardGroup isMobile={isMobile}>
        {currentData &&
          currentData.map((rental) => (
            <RentalCard
              key={rental.id}
              created={currentTab === "Created"}
              rental={rental}
              refresh={refreshRentals}
            />
          ))}
      </CardGroup>
      {/* {!currentData ||
        (currentData?.length < 1 && (
          <NoDataMessage>
            Nothing yet,{" "}
            <BrowseLink onClick={() => navigate("/browse")}>
              Browse Listings
            </BrowseLink>{" "}
            to get started!
          </NoDataMessage>
        ))} */}
    </RentalsContainer>
  );
};

export default Rentals;
