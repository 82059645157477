import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CommonButton from "../../components/CommonButton";
import useSWR from "swr";
import { ENDPOINTS } from "../../api/endpoints";
import useSavedListingsApi from "../../api/saved-listings";
import DetailsSection from "./DetailsSection";
import { useAuth0 } from "@auth0/auth0-react";
import { colors } from "../../theme";
import MessageModal from "./components/MessageModal";
import SendMessageModal from "./components/SendMessageModal";
import StarRatings from "react-star-ratings";
import { Link } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
    marginTop: 30,
  },
  name: {
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 5,
    whiteSpace: "wrap",
    maxWidth: (props) => (props.isMobile ? "90%" : "450px"),
  },
  description: {},
  price: {
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 10,
    color: "green",
  },

  reviewFont: {
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
  },
  text: {
    marginBottom: 10,
  },
  span: {
    fontWeight: "bold",
  },
  buttonGroup: {
    display: "flex",
  },
  button: {
    padding: 5,
  },
  hr: {
    borderTop: `1px solid ${colors.offWhite}`,
  },
}));

const TitleSection = (props) => {
  const { listing } = props;
  const { isAuthenticated } = useAuth0();
  const isMobile = useMediaQuery("(max-width:800px)");
  const { data: me } = useSWR(ENDPOINTS.USERS_ME);
  const classes = useStyles({ isMobile });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const {
    data: saved,
    error,
    revalidate,
  } = useSWR(`${ENDPOINTS.SAVED_LISTINGS}/${listing.id}`);
  const { toggleSaved } = useSavedListingsApi();

  const toggleSavedStatus = async () => {
    await toggleSaved(listing.id);
    revalidate();
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.name}>{listing.name}</Box>
      <Box>
        <StarRatings
          rating={listing.rating}
          starRatedColor="orange"
          // changeRating={this.changeRating}
          numberOfStars={5}
          name="rating"
          starDimension="24px"
          starSpacing="3px"
        />
        <Link to="#review-section" className={classes.reviewFont}>
          {" "}
          &nbsp;{listing.rating} ({listing.reviews.length} reviews)
        </Link>
      </Box>
      <Box className={classes.description}>{listing.description}</Box>
      <hr className={classes.hr} />
      <Box className={classes.price}>
        {listing.unit_time === "Other"
          ? `$${listing.unit_price}`
          : `$${listing.unit_price} / ${listing.unit_time}`}
      </Box>
      {listing.price_description && (
        <Box className={classes.text}>
          <span className={classes.span}>Price Description:</span>{" "}
          {listing.price_description}
        </Box>
      )}
      {isAuthenticated &&
        me &&
        listing.created_by_user_id !== me.internal_user_id && (
          <Box className={classes.buttonGroup}>
            {!error && saved && (
              <Box className={classes.button}>
                <CommonButton
                  text={
                    saved.status ? "Remove From Favorites" : "Add To Favorites"
                  }
                  buttonVariant="normal"
                  isUppercase
                  handleClick={toggleSavedStatus}
                />
              </Box>
            )}
            <Box className={classes.button}>
              <CommonButton
                text={"Send Message"}
                isUppercase
                handleClick={() => setIsModalOpen(true)}
              />
            </Box>
          </Box>
        )}
      <MessageModal
        user={me}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        listing={listing}
      />

      <SendMessageModal
        open={isRequestModalOpen}
        setOpen={setIsRequestModalOpen}
        handleClose={() => setIsRequestModalOpen(false)}
        listing={listing}
      />
      <DetailsSection listing={listing} />
    </Box>
  );
};

export default TitleSection;
