import React, { useState } from "react";
import styled from "@emotion/styled";
import SecondaryModal from "../../../../components/CommonModal/SecondaryModal";
import { colors } from "theme";
import { useForm } from "react-hook-form";
import useMessagesApi from "../../../../api/messages";
import { useNavigate } from "@reach/router";

const ModalContainer = styled.div`
  position: relative;
  background: ${colors.offWhite};
  max-width: 500px;
  min-height: 225px;
  margin: auto;
  border: 1px solid #333;
  border-radius: 3px;
  margin-top: 50px;
`;

const ModalHeader = styled.div`
  height: 50px;
  background: ${colors.blue};
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 22px;
`;

const ModalContent = styled.div``;

const ModalInput = styled.input`
  font-size: 16px;
  padding: 8px;
  outline: none;
  border: 1px solid ${(props) => (props.error ? "orange" : "#666")};
  width: 100%;
  border-radius: 3px;
`;

const ModalButton = styled.button`
  background-color: ${(props) => (props.color ? props.color : colors.navyBlue)};
  color: white;
  cursor: pointer;
  width: 150px;
  border-radius: 3px;
  padding: 8px;
  margin-top: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: absolute;
  bottom: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const InputLabel = styled.label`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 10px;
  width: 90%;
  margin: auto;
`;

const SimpleMessage = styled.div`
  padding: 15px;
  text-align: center;
  padding-bottom: 80px;
`;

const MessageModal = ({ user, open, listing, setOpen }) => {
  const { sendMessage } = useMessagesApi();

  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({});

  const handleSendMessage = async (data) => {
    // send message
    const messageData = {
      receiver_id: listing.created_by_user_id,
      listing_id: listing.id,
      message: data.message,
    };
    console.log("Message form submitted", messageData);

    try {
      await sendMessage(messageData);
      setSuccess(true);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    clearErrors();
    // setSuccess(false);
    // setError(false);
    setOpen(false);
  };

  const handleGoToDashboard = () => {
    navigate("/dashboard");
  };

  const userIsOnboarded = user?.is_onboarding_complete;
  return (
    <SecondaryModal open={open}>
      <ModalContainer>
        <ModalHeader>Send Message</ModalHeader>
        <ModalContent>
          {success && (
            <SimpleMessage>
              Message Sent! We will notify you when you receive a response via
              Email or SMS Text.
            </SimpleMessage>
          )}
          {error && (
            <SimpleMessage>
              Sorry! An unexpected error occurred. Please try again later or
              contact support.
            </SimpleMessage>
          )}
          <form onSubmit={handleSubmit(handleSendMessage)}>
            {!success && !error && userIsOnboarded && (
              <InputLabel name="Message">
                Message
                <ModalInput
                  type="text"
                  name="message"
                  placeholder="Hello, I would like to rent your..."
                  {...register("message", { required: true })}
                  error={errors.message}
                />
              </InputLabel>
            )}
            {!userIsOnboarded && (
              <SimpleMessage>
                Please finish your profile in the user dashboard to send
                messages. A phone number is required to send and receive
                messages.
              </SimpleMessage>
            )}
            <ButtonGroup>
              {!success && !error && userIsOnboarded && (
                <ButtonContainer>
                  <ModalButton type="submit">Submit</ModalButton>
                </ButtonContainer>
              )}
              {!success && !error && userIsOnboarded && (
                <ButtonContainer>
                  <ModalButton color="orange" onClick={handleCloseModal}>
                    Cancel
                  </ModalButton>
                </ButtonContainer>
              )}
              {!userIsOnboarded && (
                <ButtonContainer>
                  <ModalButton onClick={handleGoToDashboard}>
                    Go To Dashboard
                  </ModalButton>
                </ButtonContainer>
              )}

              {success && (
                <ButtonContainer>
                  <ModalButton onClick={handleCloseModal}>Ok</ModalButton>
                </ButtonContainer>
              )}
            </ButtonGroup>
          </form>
        </ModalContent>
      </ModalContainer>
    </SecondaryModal>
  );
};

export default MessageModal;
