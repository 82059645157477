import { useState } from "react";
import { IconButton, Avatar, MenuItem, Paper, Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "@reach/router";
import { AUTHENTICATED_NAV_ITEMS } from "components/NavigationBar/static";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import useSWR from "swr";
import { ENDPOINTS } from "api/endpoints";
import styled from "@emotion/styled";
import { colors } from "theme";
// import UnreadMessage from "./UnreadMessage";

const UnreadCount = styled.div`
  font-size: 12px;
  background: ${colors.pink};
  color: ${colors.offWhite};
  padding: 2px;
  border-radius: 50%;
  bottom: 0px;
  width: 22px;
  position: absolute;
  right: 50px;
  cursor: pointer;
`;

const UserProfile = () => {
  const { logout } = useAuth0();
  const { data: me } = useSWR(ENDPOINTS.USERS_ME);
  const [open, setOpen] = useState(false);
  const { data } = useSWR(ENDPOINTS.MESSAGES_UNREAD);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ position: "relative" }}>
          <Box style={{ position: "relative" }}>
            <IconButton onClick={handleClick} disableRipple>
              <Avatar src={me?.avatar_url || "assets/blue_profile.png"} />
            </IconButton>
            {data && data.unread_count > 0 && (
              <UnreadCount onClick={handleClick}>
                {data?.unread_count}
              </UnreadCount>
            )}
          </Box>
          {open && (
            <Paper sx={{ position: "absolute", top: "2rem", right: "0" }}>
              {AUTHENTICATED_NAV_ITEMS.map((item) => (
                <MenuItem key={item.title}>
                  <Link to={`${item.link}`}>{item.title}</Link>
                </MenuItem>
              ))}
              <MenuItem
                onClick={() =>
                  logout({ returnTo: process.env.REACT_APP_ORIGIN })
                }
              >
                Logout
              </MenuItem>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default UserProfile;
