import React, { useState, useEffect } from "react";
import "../style.css";
import { Button } from "@mui/material";
import { MoneyOutlined } from "@material-ui/icons";
import { navigate } from "@reach/router";
import useSWR from "swr";
import { ENDPOINTS } from "../../../api/endpoints";

function InvoiceContent({ invoiceNumber, rental }) {
  const { data: me } = useSWR(ENDPOINTS.USERS_ME);
  const [days, setDays] = useState(0);
  // const {
  //   updateRentalState
  // } = useRentalsApi();

  useEffect(() => {
    console.log(rental);
    if (rental) {
      setDays(
        Math.round(
          Math.abs(
            new Date(rental.start_date_time) - new Date(rental.end_date_time)
          ) /
            (24 * 60 * 60 * 1000)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rental]);

  const dateFormatter = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthIndex = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate =
      monthNames[monthIndex] +
      " " +
      day.toString().padStart(2, "0") +
      ", " +
      year;
    return formattedDate;
  };

  async function payInvoice() {
    localStorage.setItem("customerId", "cus_ODimE6crrEUU8x");
    localStorage.setItem("amount", days * rental.listing.unit_price);
    // await updateRentalState(rental.id, "Paid");
    navigate("/checkout");
  }

  return (
    <body>
      <article>
        <address>
          <p> RENTARTSTUFF LLC </p>
        </address>

        <table className="firstTable">
          <tr>
            <th>
              <span>Invoice #</span>
            </th>
            <td>
              <span>{invoiceNumber}</span>
            </td>
          </tr>
          <tr>
            <th>
              <span>Date</span>
            </th>
            <td>
              <span>{dateFormatter(new Date())}</span>
            </td>
          </tr>
          {/* <tr>
                        <th><span >Amount Due</span></th>
                        <td><span id="prefix" >$</span><span>600.00</span></td>
                    </tr> */}
        </table>

        <table className="secondTable">
          <thead>
            <tr>
              <th>
                <span>Item</span>
              </th>
              <th>
                <span>Description</span>
              </th>
              <th>
                <span>Rate</span>
              </th>
              <th>
                <span>Duration</span>
              </th>
              <th>
                <span>Quantity</span>
              </th>
              <th>
                <span>Price</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {rental && (
              <tr>
                <td>
                  <span class="cut">-</span>
                  <span>{rental.listing.name}</span>
                </td>
                <td>
                  <span>{rental.listing.description}</span>
                </td>
                <td>
                  <span data-prefix>$</span>
                  <span>{rental.listing.unit_price}</span>
                </td>
                <td>
                  <span>{days}</span>
                  <span data-prefix> Days</span>
                </td>
                <td>
                  <span>1</span>
                </td>
                <td>
                  <span data-prefix>$</span>
                  <span>{rental.listing.unit_price * days}</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {rental && (
          <table className="firstTable">
            <tr>
              <th>
                <span>SubTotal</span>
              </th>
              <td>
                <span data-prefix>$</span>
                <span>{rental.listing.unit_price * days}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span>Tax</span>
              </th>
              <td>
                <span data-prefix>$</span>
                <span>{rental.listing.unit_price * days}</span>
              </td>
            </tr>

            <tr>
              <th>
                <span>Platform Fee</span>
              </th>
              <td>
                <span data-prefix>$</span>
                <span>{rental.listing.unit_price * days}</span>
              </td>
            </tr>

            <tr>
              <th>
                <span>Proof</span>
              </th>
              <td>
                <span data-prefix>$</span>
                <span>{rental.listing.unit_price * days}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span>Total</span>
              </th>
              <td>
                <span data-prefix>$</span>
                <span>{rental.listing.unit_price * days}</span>
              </td>
            </tr>
          </table>
        )}
      </article>

      {me &&
        rental &&
        rental.status === "NotPaid" &&
        me.internal_user_id !== rental.owner_user_id && (
          <>
            {/* <Button style={{ float: "left" }} onClick={() => setShowModal(true)} ><Print /> &nbsp;Print</Button> */}
            <Button style={{ float: "right" }} onClick={() => payInvoice()}>
              <MoneyOutlined />
              &nbsp;PAY NOW
            </Button>
          </>
        )}
    </body>
  );
}
export default InvoiceContent;
