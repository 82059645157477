import React, { useState } from "react";
import { Grid } from "@mui/material";
import { navigate } from "@reach/router";
import { Category, CardError } from "./styled";
import styled from "@emotion/styled";

const Container = styled.div`
  padding: 10px;
  text-align: left;
  cursor: pointer;
`;

const CardImage = styled.img`
  border-radius: 5px;
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  height: 175px;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  height: 175px;
  background-color: ${(props) =>
    props.hovered ? "rgba(0, 0, 0, 0.3)" : "transparent"};
`;

const Title = styled.div`
  font-size: 18px;
  text-transform: capitalize;
  font-weight: bold;
  text-decoration: ${(props) => (props.isHovered ? "underline" : "none")};
`;

const Price = styled.div`
  font-size: 16px;
  font-weight: ${(props) => (props.isHovered ? "bold" : "normal")};
`;

const UpdatedCard = ({ listing }) => {
  const [error, setError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const titleText =
    listing.name.length >= 23
      ? `${listing.name.slice(0, 23)}...`
      : listing.name;

  const viewLink = `/listings/single/${listing.id}`;

  const handleIsHovered = () => {
    setIsHovered(true);
  };

  const handleIsNotHovered = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    navigate(viewLink);
  };
  return (
    <Grid item xs={6} md={2}>
      <Container
        onMouseEnter={handleIsHovered}
        onMouseLeave={handleIsNotHovered}
        onClick={handleClick}
      >
        <ImageContainer>
          <ImageOverlay hovered={isHovered} />
          <CardImage
            src={listing.assets[0].asset_uri || ""}
            onClick={() => navigate(viewLink)}
            onError={() => setError("Error loading image")}
          />
        </ImageContainer>
        <Category>{listing.primary_category}</Category>
        <Title isHovered={isHovered}>{titleText}</Title>
        <Price isHovered={isHovered}>
          ${listing.unit_price} / {listing.unit_time}
        </Price>

        {error && <CardError>{error}</CardError>}
      </Container>
    </Grid>
  );
};

export default UpdatedCard;
