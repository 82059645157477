import React from "react";
import styled from "styled-components";
import ContactListItem from "./ContactListItem";

const Container = styled.div`
  text-align: left;
  cursor: pointer;
`;

const ContactList = ({ contacts }) => {
  return (
    <Container>
      {contacts.map((contact, index) => (
        <ContactListItem key={index} contact={contact} />
      ))}
    </Container>
  );
};

export default ContactList;
