import React, { useState } from "react";
import { Box, useMediaQuery, Button, TextField, Modal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useRentalRequestsApi from "api/rental-requests";
import useSWR from "swr";
import { ENDPOINTS } from "api/endpoints";
import CommonButton from "components/CommonButton";
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  containerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  container: {
    backgroundColor: colors.offWhite,
    outline: "none",
    width: (props) => (props.mobile ? 600 : "50%"),
    margin: "auto",
    marginTop: 50,
    border: "1px solid black",
  },
  submit: {
    margin: 5,
    backgroundColor: colors.blue,
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: colors.blueHover,
    },
  },
  remove: {
    margin: 5,
    "&:hover": {
      backgroundColor: colors.red,
      color: "white",
    },
  },
  header: {
    height: 50,
    background: colors.blue,
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    color: "white",
    fontSize: 22,
  },
  form: {
    paddingTop: 30,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
  },
  input: {
    marginBottom: 20,
    margin: 10,
    borderWidth: 1,
  },
  inputWrapper: {
    marginTop: 15,
    marginBottom: 15,
  },
  notAllowedGroup: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  buttonWrapper: {
    display: "flex",
    flexBasis: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelButton: {
    backgroundColor: "orange",
    color: "white",
    cursor: "pointer",
    width: 150,
    borderRadius: 3,
    padding: 8,
    marginTop: 10,
  },
  acceptButton: {
    backgroundColor: "navy",
    color: "white",
    cursor: "pointer",
    width: 150,
    borderRadius: 3,
    padding: 8,
    marginTop: 10,
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 15,
  },
}));

const SendMessageModal = (props) => {
  const { open, listing, handleClose } = props;
  const mobile = useMediaQuery("(min-width:800px)");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [noMessageError, setNoMessageError] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const classes = useStyles({ mobile });
  const { data: user } = useSWR(ENDPOINTS.USERS_ME);
  const { sendRentalRequest } = useRentalRequestsApi();

  const sendRentalRequestMessage = async () => {
    console.log("message send modal");
    if (!message.length > 0) {
      setNoMessageError(true);
      return;
    }
    if (user && message.length > 0) {
      const data = {
        receiver_user_id: listing.created_by_user_id,
        message: message,
        listing_id: listing.id,
      };
      try {
        await sendRentalRequest(data);
        setIsSubmitted(true);
      } catch (e) {
        setSubmitError(
          "Could not send request. You may already have a pending request for this item, Please check your dashboard."
        );
      }
    }
  };

  const resetState = () => {
    setSubmitError(false);
    setNoMessageError(false);
    setMessage("");
    setIsSubmitted(false);
    handleClose();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box className={classes.container}>
        {!isSubmitted && (
          <Box>
            <Box className={classes.header}>Send A Message</Box>
            {user && !submitError && (
              <Box className={classes.form}>
                <div className={classes.inputWrapper}>
                  <TextField
                    id="name"
                    label="Name"
                    variant="outlined"
                    defaultValue={user?.name}
                    disabled={true}
                    size="small"
                    fullWidth
                    className={classes.input}
                  />
                </div>
                <div className={classes.inputWrapper}>
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    defaultValue={user?.email}
                    disabled={true}
                    size="small"
                    fullWidth
                    className={classes.input}
                  />
                </div>
                <div className={classes.inputWrapper}>
                  <TextField
                    id="Message"
                    label="Message"
                    variant="outlined"
                    error={noMessageError}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setNoMessageError(false);
                    }}
                    multiline
                    size="small"
                    fullWidth
                    className={classes.input}
                  />
                </div>
              </Box>
            )}
          </Box>
        )}
        {user && isSubmitted && !submitError && (
          <Box className={classes.header}>
            Thank you! your message has been sent.
          </Box>
        )}
        {user && !isSubmitted && !submitError && (
          <div className={classes.footer}>
            <div className={classes.buttonWrapper}>
              <button
                variant="outlined"
                className={classes.acceptButton}
                onClick={() => sendRentalRequestMessage()}
              >
                Submit
              </button>
            </div>
            <div className={classes.buttonWrapper}>
              <button
                variant="outlined"
                className={classes.cancelButton}
                onClick={() => resetState()}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {user && isSubmitted && !submitError && (
          <div className={classes.footer}>
            <div style={{ paddingTop: "15px" }}>
              <Button
                variant="outlined"
                className={classes.acceptButton}
                onClick={() => resetState()}
              >
                Ok
              </Button>
            </div>
          </div>
        )}
        {!user && (
          <Box className={classes.notAllowedGroup}>
            <Box textAlign="center">
              Oops. You need to create an account to send a request.
            </Box>
            <Box className={classes.cancelButton}>
              <CommonButton
                text="Ok"
                isUppercase
                handleClick={() => resetState()}
              />
            </Box>
          </Box>
        )}
        {submitError && (
          <Box>
            <Box textAlign="center">{submitError}</Box>
            <Box className={classes.button}>
              <CommonButton
                text="Ok"
                isUppercase
                handleClick={() => resetState()}
              />
            </Box>
          </Box>
        )}
      </Box>
      {/* <Box className={classes.containerWrapper}>
      </Box> */}
    </Modal>
  );
};

export default SendMessageModal;
